body {
	margin: 0;
	padding: 0;
}

.order-as-trans.eta-a-block {
	background-color: #b7ff7d;
}
.order-as-trans.eta-d-block {
	background-color: #ff8c8c;
}

tr.order-as-trans-eta .column-service .MuiChip-root {
	background-color: #b7ff7d;
}
tr.order-as-trans-etd .column-service .MuiChip-root {
	background-color: #ff8c8c;
}

tr.order-as-trans-eta.order-as-trans-etd .column-service .MuiChip-root {
	background-color: #ff8c8c;
}

tr.order-as-trans-eta.order-as-trans-etd td.column-service.MuiTableCell-root {
	background-color: #b7ff7d;
}

div.MuiToolbar-regular.toolbar-flat {
	margin: 0;
	padding: 0;
    min-height: auto;
}

div.cell-tooltip-highlight,
div.cell-btn-highlight button {
	background-color: #FFC100;
	cursor: pointer;
}
td:has(> .cell-tooltip-highlight) {
	border: solid 2px #FFC100;
}

tr.row-highlight-2 {
	background-color: #FFFF00;
}
tr.row-highlight-1 {
	background-color: #90D246;
}
tr.row-highlight-0 {
	background-color: #FFFFFF;
}
tr.row-highlight--1 {
	background-color: #DE350B;
}
.white-space-pre-wrap {
	white-space: pre-wrap;
}